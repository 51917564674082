import { RefObject, useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useLoseFocus = (ref: RefObject<any>, onLoseFocus: () => void) => {
  useEffect(() => {
    function handleExternalClick(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) onLoseFocus();
    }
    function handleEscapePressed(event: KeyboardEvent) {
      if (event.key === 'Escape') onLoseFocus();
    }

    document.addEventListener('mousedown', handleExternalClick);
    document.addEventListener('keydown', handleEscapePressed);

    return () => {
      document.removeEventListener('mousedown', handleExternalClick);
      document.removeEventListener('keydown', handleEscapePressed);
    };
  }, [ref, onLoseFocus]);
};

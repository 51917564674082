import { type Organization } from '@repo/shorebird-api';
import { UserAvatar } from './user-avatar';

export const OrganizationAvatar = ({
  organization,
  isPersonal,
  className,
}: {
  organization: Organization;
  isPersonal?: boolean;
  className?: string;
}) => {
  if (isPersonal) {
    return <UserAvatar displayName={organization.name} className={className} />;
  }

  return (
    <img
      alt="organization avatar"
      height="80"
      width="80"
      className={`h-20 w-20 ${className}`}
      src={`https://api.dicebear.com/9.x/initials/svg?seed=${organization.name}&size=80&backgroundColor=947c65&fontSize=42&radius=10`}
    ></img>
  );
};

import { useState } from 'react';
import { XIcon, AlertTriangleIcon } from 'lucide-react';

export const PatchLimitExceededAlert = () => {
  const [isClosed, setIsClosed] = useState(false);

  if (isClosed) return null;

  return (
    <div className="alert alert-warning flex items-center justify-between">
      <span className="flex items-center gap-2 text-left">
        <p>
          <span className="flex gap-2">
            <AlertTriangleIcon />
            <b>Patch install limit exceeded.</b>
          </span>
          Patches are no longer being delivered to users. Upgrade your plan to
          continue delivering patches.{' '}
          <a href="/account" className="link">
            View usage
          </a>{' '}
          for more information.
        </p>
      </span>
      <div className="flex gap-2">
        <a
          href="/account"
          className="btn-ghost btn-sm btn"
          role="menuitem"
          tabIndex={-1}
          id="menu-item-0"
          aria-label="Upgrade plan"
        >
          Upgrade
        </a>
        <button
          className="btn-ghost btn-sm btn-circle flex items-center justify-center"
          onClick={() => setIsClosed(true)}
        >
          <XIcon />
        </button>
      </div>
    </div>
  );
};

export const UserAvatar = ({
  displayName,
  className,
}: {
  displayName: string;
  className?: string;
}) => {
  return (
    <img
      alt="avatar"
      height="80"
      width="80"
      className={`h-20 w-20 ${className}`}
      src={`https://api.dicebear.com/9.x/initials/svg?seed=${displayName}&size=80&backgroundColor=00897b&fontSize=42&radius=50`}
    ></img>
  );
};
